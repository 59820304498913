import { LENGUAGE,   
    SET_ACCESS,
    USER_LOGGED_IN,
    USER_LOG_OUT,
    GET_ALL_THEMES,
    GET_ALL_USERS,
    GET_ALL_CAPSULES,
    GET_CAPSULE_ACTIVE,
    PRODUCT
  } from "./actions-types";

import axios from "axios";


export const getLenguage = (check) => {
    return {
        type: LENGUAGE,
        payload: check
    }
};

export const getProduct = (check) => {
    return {
      type: PRODUCT,
      payload: check
    }
}
export const setAccess = (boolean) => {
    return {
      type: SET_ACCESS,
      payload: boolean,
    };
  };
  
  export const userLoggedIn = (user) => {
    return async (dispatch) => {
      try {
        const { data } = await axios(`/user/${user}`);
       
        return dispatch({
          type: USER_LOGGED_IN,
          payload: data,
        });
      } catch (error) {
        console.log(error);
      }
    };
  };
  
  export const userLogOut = () => {
    return {
      type: USER_LOG_OUT,
    };
  };

  export const getAllThemes = () => {
    
    return async (dispatch) => {
        try {    
            const {data} = await axios.get('/themes');
            return dispatch({
                type: GET_ALL_THEMES,
                payload: data
       });
       
        } catch (error){
            console.log(error);
        }
    };
 };

 
export const getAllUsersAction = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/user/all`);
      
        return dispatch({
        type: GET_ALL_USERS,
        payload: data,
      });
    } catch (error) {
      console.error("Error al obtener usuarios:", error.message);
    }
  };
};

 
export const getAllCapsules = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/capsules`);
      
        return dispatch({
        type: GET_ALL_CAPSULES,
        payload: data,
      });
    } catch (error) {
      console.error("Error al obtener usuarios:", error.message);
    }
  };
};

 
export const getAllCapsuleActive = () => {
  console.log("entre a las activas")
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/active`);
      console.log(data)
        return dispatch({
        type: GET_CAPSULE_ACTIVE,
        payload: data,
      });
    } catch (error) {
      console.error("Error al obtener usuarios:", error.message);
    }
  };
};