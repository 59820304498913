/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React, {useState} from "react";
import { useSelector } from "react-redux";
import style from './Contacten.module.css';
import SlideBar from "../../SlideBar/SlideBar";

const Contacten = () => {

  const prod = useSelector((state)=>state.product);
  const [input, setInput] = useState({email: ""});
  const [errors, setErrors] = useState({email: ""});

  const validate = (state) => {
    const errors = {};
    
    const patronEmail = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
       
    if (state.email && !patronEmail.test(state.email)) errors.email = 'Entre un email válido'
    
    return errors;
}

const handleChange = (event) => {
  setInput({
    ...input,
    [event.target.name]: event.target.value,
  });
  setErrors(
    validate({
      ...input,
      [event.target.name]: event.target.value,
    })
  );
};

  return (
    <div className={style.contact} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
      <div className={style.program}>
      <div className={style.contenedor}>
      <h1>Contact us</h1>
      <p>To visit the farm you must make a reservation beforehand</p>
      <SlideBar/>
      </div>
      <h1>Location</h1>
      <p>Km 8.5 San Antonio sector. Via to Rosario - La Buitrera. Cali - Colombia</p>
      <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d127452.51193627599!2d-76.73489890273433!3d3.376810000000026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sla%20granja%20de%20pili!5e0!3m2!1ses-419!2sco!4v1705763463025!5m2!1ses-419!2sco" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className={style.frame}></iframe>
      </div>
   
    <div id= "contact" className={style.content}>
      <h1 >Write us</h1>
      <form
        action="https://getform.io/f/2261747c-69b5-456a-b5e8-1d7aed9f024f"
        method="POST"
       
      >
        <input
          type="text"
          name="name"
          placeholder="Name"
          
        />
        <input
          type="text"
          name="email"
          placeholder="Email"
          onChange={handleChange}
        />
        {errors.email && <p>{errors.email}</p>}
        <textarea
          name="message"
          placeholder="Message"
          id=""
          resize="none"
        ></textarea>
        <button
          type="submit"
        >
          Send
        </button>
      </form>
      
    </div>

    </div>
  );
};

export default Contacten;
