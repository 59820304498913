import { LENGUAGE,
        SET_ACCESS,
        USER_LOGGED_IN,
        USER_LOG_OUT,
        GET_ALL_THEMES,
        GET_ALL_USERS,
        GET_ALL_CAPSULES,
        GET_CAPSULE_ACTIVE,
        PRODUCT
    } from "../actions/actions-types";


const initialState = {
    lenguage: true,
    product: false,
    access:false,
    activeUser:{},
    allThemes: [],
    allUsers:[],
    allCapsules:[],
    capsulesActive: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case LENGUAGE:
            return {
                ...state,
                lenguage: action.payload
            }
        case PRODUCT:
            return {
                ...state,
                product: action.payload
            }
        case SET_ACCESS: {
            return {
                ...state,
                access: action.payload,
            };
        }
        case USER_LOGGED_IN: {
            return {
                ...state,
                activeUser: action.payload,
            };
        }
        case USER_LOG_OUT: {
            return {
                ...state,
                activeUser: {},
            };
        }
        
        case GET_ALL_THEMES:
            return {
                ...state,
                allThemes: action.payload
            }
        case GET_ALL_USERS:
            return {
                ...state,
                allUsers: action.payload
            }
        case GET_ALL_CAPSULES:
            return {
                ...state,
                allCapsules: action.payload
            }
        case GET_CAPSULE_ACTIVE:
            return {
                ...state,
                capsulesActive: action.payload
            }
        default:
            return {...state
            }
        }
};

export default reducer;