const validate = ({usuario,contraseña}) => {
  const errors = {};

  if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(usuario)) {
    errors.usuario = "Debes incluir un email valido";
  }

  if (usuario.length > 35) {
    errors.usuario = "Email debe tener menos de 250 caracteres";
  }
  if (usuario === "") {
    errors.usuario = "El campo no debe estar vacio";
  }

  /*if (!/\d/.test(contraseña)) {
    errors.contraseña = "La contraseña debe contener al menos un número";
  }

  if (!(contraseña.length >= 6 && contraseña.length <= 20)) {
    errors.contraseña = "La contraseña debe contener entre 6 y 20 caracteres.";
  }

  if(!/[A-Z]/.test(contraseña)) errors.contraseña = "La contraseña debe tener al menos una letra mayúscula"*/

  return errors;
}

export default validate;