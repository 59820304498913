import s from './footer.module.css';
import SlideBar from '../SlideBar/SlideBar';

const Footer = () => {
  return (
    <div className={s.container}>
       
      <div className={s.copyright}>

        <p>Copyright © 2024 - Luis Lenis - All rights reserved</p>
        <div className = {s.redes}>
        <SlideBar/>
        </div>
      </div>
    </div>
  );
}

export default Footer;