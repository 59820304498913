import Agrotourism from '../Agrotourismen/Agrotourismen';
import Farmersen from '../Farmersen/Farmersen';
import Ecoworkingen from '../Ecoworkingen/Ecoworkingen';
import { useSelector } from 'react-redux';
//import style from './Services.module.css'

const Servicesen = () =>{
    const prod = useSelector((state)=>state.product);
    return(
    <div style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
        <Agrotourism/>
        <hr></hr>
        <Farmersen/>
        <hr></hr>
        <Ecoworkingen/>
    </div>
    )
}

export default Servicesen;