import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import validate from "./validate";
import { useParams, useNavigate } from "react-router-dom";
import { getAllThemes } from "../../redux/actions/index";
import s from "./EditCapsule.module.css";




const EditCapsule = () => {

  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themes = useSelector((state) => state.allThemes);
  const [input, setInput] = useState({
    spanish: "",
    english: "",
    active: true,
  });

  const [errors, setErrors] = useState({
    spanish: "",
    english: "",

  });

  
useEffect (() => {
    dispatch(getAllThemes());
  }, [])

useEffect (() => {
  async function getByID() {
    const { data } = await axios.get(`capsule/${id}`)
    setInput(data)
}
getByID()
}, [id, dispatch])

console.log(input)

  const mostrarAlerta = (iconType, msjText) => {
    Swal.fire({
      confirmButtonColor: '#edb40b',
      icon: iconType,
      title: "",
      text: msjText,
    });
  };

  const formHandler = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
    
    setErrors(
      validate({
        ...input,
        [event.target.name]: event.target.value,
      })
    );
  };

  const comeback = () => {
    navigate('/capsulesadmin');
  }

  const esVacio= (elemento) => {
    return elemento === "";
  } 

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      
      let long = Object.values(errors);
      if (long.every(esVacio)) {
        console.log(input)
        await axios.put(`/capsule/${id}`, input);
        mostrarAlerta("success", "La capsula se actualizó con éxito");
         
      } else if (long.length !==0) mostrarAlerta("error", "Debes completar los campos sin errores");
    } catch (error) {
      console.log(error);
      mostrarAlerta("error", error.response.data);
    }
  };

  return (
    <div className={s.form__container}>
      <form className={`${s.form} ${s["s-form"]}`} onSubmit={submitHandler}>
      <button onClick={comeback} className ={s.close}>X</button>
        <fieldset>
          <legend>Editar Capsula</legend>

            <label>Español*:</label>
            <br/>
            <textarea
            name="spanish"
            value={input.spanish}   
            onChange={formHandler}
            />
            {errors.spanish && <p>{errors.spanish}</p>}

            <label>Inglés*:</label>
            <br/>
            <textarea
            name="english"
            value={input.english}
            onChange={formHandler}
          />
          {errors.english && <p>{errors.english}</p>}

          <label>Tema*: </label>
          <br/>
        <select onChange={formHandler} name="theme" id="theme" value={input.theme}>
          <option hidden>seleccione un tema</option>{
            themes?.map((b)=><option key={b} value={b.id}>{b.name}</option>)
          }
        </select>
        {errors.theme && <p>{errors.theme}</p>}
          <br/>
         <span>*Obligatorio</span>
          <br></br>
          <button className={s.btnC} type="submit">Actualizar</button>
        </fieldset>
      </form>
    </div>
  );
};

export default EditCapsule;
