import Heroen from '../../English/Heroen/Heroen';
import Empresasen from '../../English/Empresasen/Empresasen';
import Presentationen from '../Presentationen/Presentationen';
import Biodiversityen from '../../English/Biodiversityen/Biodiversityen';
import Activitiesen from '../../English/Activitiesen/Activitiesen';
import { useSelector } from 'react-redux';

const Homen = () =>{

    const prod = useSelector((state)=>state.product);
    return<div style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
        <Heroen/>
        <Presentationen/>
        <Biodiversityen/>
        <Activitiesen/>
        <Empresasen/>
    </div>
}

export default Homen;