import empresa1 from "../../../assets/Empresas/Acuaponia.png";
import empresa2 from "../../../assets/Empresas/Colombia Ecológica.jpeg";
import empresa3 from "../../../assets/Empresas/Nasa de Colombia.jpeg";
import empresa4 from "../../../assets/Empresas/Jungla solar.jpg";
import style from "./Empresasen.module.css";
const Empresasen = () => {

return (
    <div className={style.contemp}>
        <p className={style.aliadas}>Our Allied Companies</p>
        <p className={style.industria}>Industry Leaders</p>
        <div className={style.empresas}>
            <img src={empresa1} alt=""/>
            <img src={empresa2} alt=""/>
            <img src={empresa3} alt=""/>
            <img src={empresa4} alt=""/>
        </div>

    </div>




)


}

export default Empresasen;