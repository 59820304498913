import {useState} from "react";
import style from './Farmers.module.css';
import nomada from '../../assets/Farmers/Explicacion.jpg';
import sala from '../../assets/Farmers/En la Paca.jpg';
import salon from '../../assets/Farmers/Subiendo.jpg';
import {BsWhatsapp} from "react-icons/bs";


const Farmers = () => {

    const [image, setImage] = useState(salon);


    const handleImage = (event) =>{
         setImage(event.target.name)
    }

    return (
        <div id="farm" className={style.service}>
            <div className={style.imagenes}>
                <img src={image} alt="" className={style.big} />
                <div className={style.littles}>
                    <img src={nomada} alt="" name={nomada} onClick={handleImage}/>
                    <img src={sala} alt="" name={sala} onClick={handleImage}/>
                    <img src={salon} alt="" name={salon} onClick={handleImage}/>
                </div>
            </div>    
            <div className={style.containt}>
                    <h1 >PROGRAMA GRANJERITOS</h1>
                    
                        <p>La granja como centro experimental para la conservación del medio ambiente le ofrece a los colegios la oportunidad para que sus estudiantes puedan aprender de manera divertida a:</p>
                        <p>Sembrar en la huerta</p>
                        <p>Recoger los huevos de las gallinas felices</p>
                        <p>Interactuar con los patos</p>
                        <p>Dar de comer a los ovejos</p>
                        <p>Alimentar a los peces</p>
                        <p>Preparar compostaje</p>
                        <p>Interactuar con los conejos</p>
                        <p>Horario: 8:00 a.m a 1:00 p.m. de Lunes a Viernes </p>
                        <p>Reservas:  < BsWhatsapp  size={25}/> 3116233078</p>
                    
            </div>
         
        </div>
    )




}

export default Farmers;
