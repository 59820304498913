
import style from './About.module.css'
import fotopili from '../../assets/Pilar_conejo2.png'
import fotoluisma from '../../assets/Luismita.jpg'
import libro from '../../assets/Portada Pili.jpg'
import { Link } from  "react-router-dom"
import { useSelector } from 'react-redux'

const About = () =>{
 
    const prod = useSelector((state)=>state.product);
  
     return(
        <div className={style.about} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
            <div className={style.container}>
                <h1>¿Quienes somos?</h1>
                <p>La Granja de Pili es un centro experimental para la conservación del medio ambiente
                que tiene como misión transmitir conocimientos sobre procesos de producción sostenible,
                uso de energías renovables, conservación de especies nativas y soberanía alimentaria.
                </p>
                <p>
                Buscamos despertar en las personas el cuidado por los recursos naturales y mostrarles como
                adoptar buenas prácticas medioambientales. Brindamos
                asesoría en formulación y ejecución de proyectos de conservación ambiental, producción agropecuaria
                sostenible y eficiencia energética.
                </p>
            </div>

            <div className={style.presentation}>
                <div className={style.pili}>
                    <img src={fotopili} alt="foto Pilar"/>
                    <Link to ='/acknowledge'><img src={libro} alt="libro Pilar" className={style.libro}/></Link>
                    <h3>Maria del Pilar Dacosta Chavez</h3>
                    <p>Zootecnista de la Universidad Nacional con especialización en Nutrición Animal Sostenible. Técnico en aprovechamiento
                    sustentable de la biodiversidad vegetal. Estudios en economía circular y producción
                    animal ecológica. En el 2023, Maria del Pilar fue incluida en el <Link to='/acknowledge'>Libro Ellas Emprenden</Link> de Promujer.
                    </p>
                    
                </div>
                
                <div className={style.luisma}>
                    <img src={fotoluisma} alt="foto Luisma"/>
                    <h3>Luis Mario Lenis Gil</h3>
                    <p>Ingeniero en Electrónica de la Universidad del Cauca especialista en Sistemas Gerenciales de Ingeniería. Master en medio ambiente
                        y Energías Renovables. Certificado por el PMI en Gestión de Proyectos. Desarrollador de software.
                    </p>
                </div>
            </div>


       </div>
      
        
     )
     }
  export default About