// Acá deben declarar los types de las actions.


export const LENGUAGE = "LENGUAGE";
export const SET_ACCESS = "SET_ACCESS";
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const GET_ALL_THEMES = "GET_ALL_TEAMS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_CAPSULES = "GET_ALL_CAPSULES";
export const GET_CAPSULE_ACTIVE = 'GET_CAPSULE_ACTIVE';
export const PRODUCT = "PRODUCT";
