/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import style from "./Banner.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

//import { useSelector } from "react-redux";


const Banner = ( {banner, handlePrevBanner, handleNextBanner}) => {
  const { id, title, text, image } = banner;
  console.log(banner);

  

  return (
    <div className={style.banner}>

      <div className={style.caja}>
        <img src={image} alt="" />
        <div className={style.containText}>
          <h1>{title}</h1>
          <p>{text}</p>
        </div>
      </div>
      <div className={style.botones}>
        <button
          onClick={handlePrevBanner}> <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <button
          onClick={handleNextBanner}> <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default Banner;
