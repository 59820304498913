/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import s from './Biodiversity.module.css';
import Landscape from '../../assets/Biodiversity/Landscape.jpg';
import Guatin from '../../assets/Biodiversity/guatin.jpg';
import Guacharaca from '../../assets/Biodiversity/Guacharaca.jpg';

const Biodiversity = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [Landscape, Guacharaca, Guatin ];
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);


  const backgroundImageStyle = {
    backgroundImage: `url(${images[currentImage]})`,
  };

  return (
    <div className={s.bio}>
        <p>Sobre nuestra riqueza</p>
        <div className={s.bioContainer} style={backgroundImageStyle}>
            <div className={s.bioText}>
            <h2>Colombia es el segundo país más biodiverso del mundo, siendo hábitat del 20% de las aves y 20% de las especies de plantas del planeta</h2>
            <h2>Cali está rodedada por 7 ríos, tiene 12 ecoparques, 61 humedales y es hogar de casi 600 especies de aves de las más de 1770 que habitan en Colombia</h2>
            <h2>En los farallones de Cali donde estámos ubicados habitan guatines, guacharacas, armadillos, zorros, zarigüeyas, ardillas entre otras especies</h2>
            </div>
        </div>
    </div>
  );
};

  
    

export default Biodiversity