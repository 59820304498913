import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import About from './components/About/About';
import Abouten from './components/English/Abouten/Abouten';
import Contact from './components/Contact/Contact';
import Contacten from './components/English/Contacten/Contacten';
import Login from './components/Login/Login';
import Loginen from './components/English/Loginen/Loginen';
import Footer from './components/Footer/Footer';
//import Courses from "./components/Courses/Courses";
import Services from './components/Services/Services';
import Servicesen from './components/English/Servicesen/Servicesen';
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import CreateUser from "./components/CreateUserForm/CreateUserForm";
import CreateUseren from './components/English/CreateUseren/CreateUseren';
import Navbaren from "./components/English/Navbaren/Navbaren";
import Homen from "./components/English/Homen/Homen";
import { Routes, Route} from "react-router-dom";
import Acknowledge from './components/Acknowlegde/Acknowledge';
import Acknowledgen from './components/English/Acknowledgen/Acknowledgen';
import Capsules from './components/Capsules/Capsules';
import Dashboard from './components/Dashboard/Dashboard';
import EditPerfilForm from './components/EditPerfilForm/EditPerfilForm';
import EditPerfilen from './components/English/EditPerfilen/EditPerfilen';
import {setAccess, userLoggedIn} from './redux/actions/index';
import './App.css';
import axios from 'axios';
import UsersTable from './components/UsersTable/UsersTable';
import CapsuleTable from './components/CapsuleTable/CapsuleTable';
import CreateCapsule from './components/CreateCapsule/CreateCapsule';
import EditCapsule from './components/EditCapsule/EditCapsule';
import Products from './components/Products/Products';

axios.defaults.baseURL="https://la-granja-de-pili-production.up.railway.app/";


//axios.defaults.baseURL="http://localhost:3001";

function App() {

  const storedToken = localStorage.getItem("token");
  const [token, setToken] = useState(storedToken || "");
  const leng = useSelector((state)=>state.lenguage);
  const prod = useSelector((state)=>state.product);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        dispatch(setAccess(true));
        dispatch(userLoggedIn(decodedToken.email));
      } catch (error) {
        console.error("Error decoding token:", error);
        // Manejar el error de decodificación aquí, por ejemplo, redirigir al usuario o realizar otra acción apropiada.
      }
    }
  }, [dispatch, token]);

 if(leng) {
  return (
    <div >
     
       <Navbar />
       <Products/>
      
     <Routes >
      
      <Route exact path='/' element = {<Home/>} />
      <Route exact path = '/about' element = {<About style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}/>}/>
       {/*<Route exact path='/courses' element={<Courses/>}/>*/} 
       <Route exact path = '/activities' element={<Services/>}/>
       <Route exact path = '/contact' element={<Contact/>}/>
       <Route exact path = '/login' element={<Login setToken={setToken}/>}/>
       <Route exact path = '/createuser' element={<CreateUser/>}/>
       <Route exact path = '/acknowledge' element={<Acknowledge/>}/>
       <Route exact path = '/capsules' element={<Capsules/>}/>
       <Route exact path = '/editperfil/:email' element={<EditPerfilForm/>}/>
       <Route exact path = '/dashboard' element={<Dashboard/>}/>
       <Route exact path = '/usersadmin' element={<UsersTable/>}/>
       <Route exact path = '/capsulesadmin' element={<CapsuleTable/>}/>
       <Route exact path = '/createcapsule' element={<CreateCapsule/>}/>
       <Route exact path = '/editcapsule/:id' element={<EditCapsule/>}/>
       
     </Routes>
     
     <Footer/>
   </div>
 
  );
 }  else {
        return (
          <div>
            
             <Navbaren/>
             <Products/>
           <Routes>
            <Route exact path='/' element = {<Homen/>} />
            <Route exact path = '/about' element = {<Abouten/>}/>
             {/*<Route exact path='/courses' element={<Courses/>}/>*/} 
             <Route exact path = '/acknowledge' element={<Acknowledgen/>}/>
             <Route exact path = '/activities' element={<Servicesen/>}/>
             <Route exact path = '/contact' element={<Contacten/>}/>
             <Route exact path = '/login' element={<Loginen setToken={setToken}/>}/>
             <Route exact path = '/createuser' element={<CreateUseren/>}/>
             <Route exact path = '/editperfil/:email' element={<EditPerfilen/>}/>
             <Route exact path = '/dashboard' element={<Dashboard/>}/>
             <Route exact path = '/capsules' element={<Capsules/>}/>
             
           </Routes>
           <Footer/>
         </div>
       
        );
 }
}

export default App;
