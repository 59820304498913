import Agrotourism from '../Agrotourism/Agrotourism';
import Farmers from '../Farmers/Farmers';
import Ecoworking from '../Ecoworking/Ecoworking';
import { useSelector } from 'react-redux';
import style from './Services.module.css'

const Servicesen = () =>{

    const prod = useSelector((state)=>state.product);
    return(
    <div className={style.contenedor} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
        <Agrotourism/>
        <hr></hr>
        <Farmers/>
        <hr></hr>
        <Ecoworking/>
    </div>
    )
}

export default Servicesen;