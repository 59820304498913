// Hero.js
import React, { useState} from 'react';
//import s from './hero.module.css';
import Banner from '../../Banner/Banner';
import colection1Image from '../../../assets/Cabaña3.jpg';
import colection2Image from '../../../assets/Gallinas1.jpg';
import colection3Image from '../../../assets/Energias.png';

const Hero = () => {
  let currentCards = [];
  

  const images = [colection1Image, colection2Image, colection3Image];
  const title = ["What is within our reach to mitigate global warming?",
                  "Do you want to know more about food sovereignty?", 
                  "How do renewable energies work?"];
  const text = ["From home we can implement actions to take advantage of organic solid waste, apply the circular economy and take advantage of rainwater",
                "We teach you how to produce chemical-free food in small spaces, how to build organic gardens and how to produce compost",
              "With us you can learn how a photovoltaic system, a solar water heater or a biodigester works"]

  
  for (let i=0; i<= 3; i++) {
      currentCards.push({id:i, image: images[i], title:title[i] , text:text[i]})
  }
  const [currentBanner, setCurrentBanner] = useState(currentCards[0]);
 
  
  const handleNextBanner = () => {
    let currentPosition = currentBanner.id
    currentPosition++;
    if (currentPosition > currentCards.length-2) {
       currentPosition = 0;
     }
    setCurrentBanner(currentCards[currentPosition]);
   };

   const handlePrevBanner = () => {
    let currentPosition = currentBanner.id
    currentPosition--;
    if (currentPosition < 0) {
       currentPosition = 2;
     }
    setCurrentBanner(currentCards[currentPosition]);
   };


  return (
    <div >
        <div>
          
            {<Banner
              banner={currentBanner}
              handleNextBanner={handleNextBanner}
              handlePrevBanner={handlePrevBanner}

            />}
        
        </div>

     
    </div>
  );
};

export default Hero;