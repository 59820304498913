
//import React, { useState} from 'react';
//import Banner from '../Banner/Banner';
//import {Link} from 'react-router-dom';
import Image1 from '../../assets/Activities/Agroturismo.png';
import Image2 from '../../assets/Activities/Granjerito.jpg';
import Image3 from '../../assets/Activities/Coworking.png';
import style from './Activities.module.css';

const Activities = () => {
  let currentCards = [];
  

  const images = [Image1, Image2, Image3];
  const title = ["Agroturismo",
                  "Programa Granjeritos", 
                  "Ecoworking"];
  const href = ["/activities/#agro", "/activities/#farm", "/activities/#eco"]
  
  for (let i=0; i< 3; i++) {
      currentCards.push({id:i, image:images[i], title:title[i], href:href[i]})
  }
   
return (
    <div className={style.actividad}>
      <p className={style.title}>Actividades en la Granja</p>
      <div className={style.tarjetas}>
        {currentCards.map((p) => (
          <div key={p.id}>
            <a href={p.href}>
            
              <div className={style.card}>
                <img className={style.imagen}src={p.image} alt="" />
                <div className={style.descripcion}>
                    <h2 className={style.name}>{p.title}</h2>
                    
                </div>
              </div>
            
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activities;