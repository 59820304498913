import { useState, useEffect } from "react";
import axios from "axios";
import s from "./Loginen.module.css";
import { Link, useNavigate } from "react-router-dom";
import SocialLogin from "../../SocialLogin/SocialLogin";
import { useDispatch, useSelector } from "react-redux";
import { setAccess, userLoggedIn } from "../../../redux/actions/index";
import Swal from "sweetalert2";
import validate from "./validate";



const Loginen = ({ setToken }) => {
  const [loginInput, setLoginInput] = useState({
    usuario: "",
    contraseña: "",
  });
  const [errors, setErrors] = useState({
    usuario: "",
    contraseña: "",
  });

   const navigate = useNavigate();
   const access = useSelector((state) => state.access);
   const prod = useSelector((state)=>state.product);
   const dispatch = useDispatch();

  const mostrarAlerta = (iconType, msjText) => {
    Swal.fire({
      confirmButtonColor: '#edb40b',
      icon: iconType,
      title: "",
      text: msjText,
    });
  };


   const formHandler = (event) => {
     setLoginInput({
       ...loginInput,
      [event.target.name]: event.target.value,
     });
     setErrors(
       validate({
         ...loginInput,
         [event.target.name]: event.target.value,
       })
     );
   };
// console.log(URL)
   const handleSubmit = async (e) => {
     e.preventDefault();
     try {
       const response = await axios(`/user/${loginInput.usuario}`);
       if (!response.data) {
         mostrarAlerta("error", "User is not registered yet.");
         return;
       }
      if (response.data.provider === "google") {
         mostrarAlerta(
          "error",
           "Email is already associated with the Google account"
         );
         return;
       }
      const { data } = await axios(
        `/user/login/?email=${loginInput.usuario}&password=${loginInput.contraseña}`
      );
      const newToken = data.token;
      setToken(newToken);
      localStorage.setItem("token", newToken);
      dispatch(setAccess(data.access));
      dispatch(userLoggedIn(loginInput.usuario));
      
    } catch (error) {
      mostrarAlerta("error", error.response.data.error);
    }
  };

   useEffect(() => {
    if (access) navigate("/");
   }, [access]);

  return (
    <div className={s.containerlog} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
    <section className={s["login-container"]}>
      <hr />
      <h2>La Granja de Pili</h2>
      <hr />
      <h2>Access</h2>
      <form className={s["login-form"]} onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            placeholder="Email"
            name="usuario"
            value={loginInput.usuario}
            onChange={formHandler}
          />
          <div className={s.error__container}>
            {errors.usuario && <p className={s.error}>{errors.usuario}</p>}
          </div>
        </label>
       
        <label>
          <input
            type="password"
            placeholder="Password"
            name="contraseña"
            value={loginInput.contraseña}
            onChange={formHandler}
          />
          <div className={s.error__container}>
            {errors.contraseña && (
              <p className={s.error}>{errors.contraseña}</p>
            )}
          </div>
        </label>
        
        <button type="submit">Login</button>
        <p>{`Do not you have an account yet?`}<Link to='/createuser'>¡Sign up here!</Link></p>
      </form>
      {/*<h3 className={s.or__h3}> OR </h3>*/}
      <div>
        {/*<SocialLogin />*/}
      </div>
    </section>
    </div>
  );
};

export default Loginen;