import React, { useState} from 'react';
import { useSelector } from 'react-redux';
//import s from './hero.module.css';
import style from "./Acknowledgen.module.css";
import colection1Image from '../../../assets/Acknowledge/Foto Promujer.png';
import colection2Image from '../../../assets/Acknowledge/Portada1.png';
import colection3Image from '../../../assets/Acknowledge/Portada2.png';
import colection4Image from '../../../assets/Acknowledge/historia1.png';
import colection5Image from '../../../assets/Acknowledge/historia2.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
const Acknowledgen = () => {

const prod = useSelector((state)=>state.product);
const imagePilar = colection1Image;
const portada1 = colection2Image;
const portada2 = colection3Image;
const historia1 = colection4Image;
const historia2 = colection5Image;
 
  //const images = [colection1Image, colection2Image];
  const text = 
  '"Ellas emprenden" is a tribute to the micro-entrepreneurial women of Latin America, a song to their courage, their resilience and call to action. Maria del Pilar was selected by Colombia to be part of this first Pro Mujer book with 16 stories of Latin American women';



const [currentBanner, setCurrentBanner] = useState(0);

  
 
const handleNextBanner = () => {
    let currentPosition = currentBanner
    currentPosition++;
    if (currentPosition > 2) {
       currentPosition = 0;
     }
    setCurrentBanner(currentPosition);
   };

   const handlePrevBanner = () => {
    let currentPosition = currentBanner
    currentPosition--;
    if (currentPosition < 0) {
       currentPosition = 2;
     }
    setCurrentBanner(currentPosition);
   };

if (currentBanner===0){

  return (
    <div className={style.photos} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
          
        <div className={style.contentpili}>
            <img  src={imagePilar} alt="" />
            <div className={style.containText}>
                <p>{text}</p> 
            </div>
        </div>
            <button
                onClick={handlePrevBanner}> <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
                onClick={handleNextBanner}> <FontAwesomeIcon icon={faArrowRight} />
            </button>
        
                   
    </div>
  );
} else if (currentBanner===1){
    return (
        <div className={style.contentlibro} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
            <div className={style.todocontent}>

             <div className={style.libro}>
                <img src={portada1} alt="" />
                <img src={portada2} alt="" />
            </div>
            <div className={style.botones}>
            <button
                onClick={handlePrevBanner}> <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
                onClick={handleNextBanner}> <FontAwesomeIcon icon={faArrowRight} />
            </button>
            </div>
            </div>
        </div>
    )
} else if (currentBanner===2) {
    return (
        <div className={style.contentlibro} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
            <div className={style.todocontent}>
                
                <div className={style.librohistoria}>
                    <img src={historia1} alt="" />
                    <img src={historia2} alt="" />
                </div>
                <div className={style.botones}>
                <button
                    onClick={handlePrevBanner}> <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                    onClick={handleNextBanner}> <FontAwesomeIcon icon={faArrowRight} />
                </button>
                </div>
            </div>
        </div>
    )
}
};

export default Acknowledgen;