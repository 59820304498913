import {useState} from "react";
import style from './Agroen.module.css';
import entrada from '../../../assets/Agrotourism/Entrada.jpg';
import conejo from '../../../assets/Agrotourism/Pilar_conejo.jpg';
import gallinas from '../../../assets/Agrotourism/Gallinas.jpg';
import {BsWhatsapp} from "react-icons/bs";


const Agrotourismen = () => {

    const [image, setImage] = useState(entrada);


    const handleImage = (event) =>{
         setImage(event.target.name)
    }

    return (
        <div id="agro" className={style.aservice}>
            <div className={style.aimagenes}>
                <img src={image} alt="" className={style.abig} />
                <div className={style.alittles}>
                    <img src={entrada} alt="" name={entrada} onClick={handleImage}/>
                    <img src={conejo} alt="" name={conejo} onClick={handleImage}/>
                    <img src={gallinas} alt="" name={gallinas} onClick={handleImage}/>
                </div>
            </div>    
            <div className={style.atext}>
                    <h1>AGROTOURISM</h1>
                    <p>We invite you to take a fun tour where you can find.</p>
                    <p>Aquaponics</p>
                    <p>Organics orchards</p>
                    <p>Rainwater Collection System</p>
                    <p>Grazing Laying Hens</p>
                    <p>Soldier Fly Culture</p>
                    <p>Bird watching</p>
                    <p>Apiculture (optional)</p>
                    <p>You can hire it with Breakfast and/or Lunch</p>
                    <p>Hours: 8:00 a.m. to 5:p.m. Monday to Friday</p>
                    <p>Bookings:  < BsWhatsapp  size={25}/> 3116233078</p>
            </div>
         
        </div>
    )




}

export default Agrotourismen;