// ProductsTable.jsx
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAllCapsuleActive, getAllCapsules } from "../../redux/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import s from "./CapsuleTable.module.css"



const CapsuleTable = () => {
  const dispatch = useDispatch();
  const allCapsules = useSelector((state) => state.allCapsules);

  useEffect(() => {
    dispatch(getAllCapsules());
  }, []);
  const updateHandler = async (id) => {
    await axios.put(`/capsule/restore/${id}`);
    dispatch(getAllCapsules())
    dispatch(getAllCapsuleActive())
  };

  console.log(allCapsules);

  return (
    <div className={s.capsulecontainer}>
      <button className={s.btnC}>
       <Link className={s.link} to="/createcapsule">
          <FontAwesomeIcon icon={faPlus} />
          Crear Capsula
        </Link>
        </button>
      <h2>Capsulas</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Español</th>
            <th>Inglés</th>
            <th>Temática</th>
            <th>Editar</th>
            <th>Activar</th>
          </tr>
        </thead>
        <tbody>
          {allCapsules.map((product) => (
            <tr key={product.id} className={!product.active && s.disabled}>
              <td>{product.id}</td>
              <td>{product.spanish}</td>
              <td>{product.english}</td>
              <td>{product.Theme?.name}</td>
              <td>
                <Link to={`/editcapsule/${product.id}`}>
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              </td>
              <td>
                <button onClick={() => updateHandler(product.id)}>
                  <FontAwesomeIcon icon={product.active ? faBan: faCheck} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CapsuleTable;