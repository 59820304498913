/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import s from './Biodiversityen.module.css';
import Landscape from '../../../assets/Biodiversity/Landscape.jpg';
import Guatin from '../../../assets/Biodiversity/guatin.jpg';
import Guacharaca from '../../../assets/Biodiversity/Guacharaca.jpg';

const Biodiversityen = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const images = [Landscape, Guacharaca, Guatin ];
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);


  const backgroundImageStyle = {
    backgroundImage: `url(${images[currentImage]})`,
  };

  return (
    <div className={s.bio}>
        <p>About our wealth</p>
        <div className={s.bioContainer} style={backgroundImageStyle}>
            <div className={s.bioText}>
            <h2>Colombia is the second most biodiverse country in the world, being the habitat of 20% of the birds and 20% of the plant species on the planet</h2>
            <h2>Cali is surrounded by 7 rivers, has 12 ecoparks, 61 wetlands and is home to almost 600 species of birds of the more than 1,770 that live in Colombia</h2>
            <h2>In the mountains of Cali where we are located, guatines, guacharacas, armadillos, foxes, opossums, squirrels among other species live.</h2>
            </div>
        </div>
    </div>
  );
};

  
    

export default Biodiversityen