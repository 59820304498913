import Hero from '../Hero/Hero';
import Empresas from '../Empresas/Empresas';
import Presentation from '../Presentation/Presentation';
import Biodiversity from '../Biodiversity/Biodiversity';
import Activities from '../Activities/Activities';
import style from './Home.module.css';
import { useSelector } from 'react-redux';


const Home = () =>{

    const prod = useSelector((state)=>state.product);
    return<div className={style.contenido} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
        <Hero/>
        <Presentation/>
        <Biodiversity/>
        <Activities/>
        <Empresas/>
    </div>
}

export default Home;