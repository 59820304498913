import { useDispatch, useSelector } from 'react-redux';
import Huevos from '../../assets/Products/huevos.png';
import style from './Products.module.css';
import { getProduct } from '../../redux/actions';

const Products = () => {
  
  const prod = useSelector((state)=>state.product);
  const leng = useSelector((state)=>state.lenguage);
  const dispatch = useDispatch();

  let leyenda = "";
  let title = "";
  const images = Huevos;
  const price = "$24000";
   
  if(leng) {
    leyenda = "Domicilios al sur de Cali $3000";
    title = "PANAL HUEVO CRIOLLO";
  }
  else{
    leyenda = "Homes south of Cali $3000"; 
    title = "PEASANT EGG COMB"
  } 

  
  const closing = () => {
    dispatch(getProduct(false));
  }
return (
            <div style = {{ display:prod ? 'block':'none'}}>
              <div className={style.card}>
                
                <button onClick={closing} className ={style.closer}>x</button>
                <h2 className={style.nametitle}>{title}</h2>
                <img className={style.imagen}src={images} alt="" />
                <div className={style.descripcion}>
                    <h2 className={style.name}>{price}</h2>
                    <h4 className={style.content}>{leyenda}</h4>
                </div>
              </div>
              </div>
            
        
        )
      
};

export default Products;