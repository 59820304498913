
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BannerCapsule from '../BannerCapsule/BannerCapsule';
import Image1 from '../../assets/Capsulas/PNG pollo sostenibilidad.png';
import Image2 from '../../assets/Capsulas/PNG pollo cuidado ambiental.png';
import Image3 from '../../assets/Capsulas/PNG pollo huevo.png';
import Image4 from '../../assets/Capsulas/PNG pollo energía renovable.png';
import style from './Capsules.module.css';
import axios from 'axios';


const Capsules = () => {

  const dispatch = useDispatch();
  const leng = useSelector((state)=>state.lenguage);
  const prod = useSelector((state)=>state.product);
  const [allCapsules, setAllCapsules] = useState("");
  const [currentBanner, setCurrentBanner] = useState("");
  
  useEffect(() => {
    async function getByID() {
      const { data } = await axios.get(`/active`)
      setAllCapsules(data)
      setCurrentBanner(data[0])
  }
  getByID()
  
  }, [dispatch]);

  // useEffect(() => {
  //   setCurrentBanner(allCapsules[0]);
  // }, [allCapsules]);

  console.log(allCapsules)
  console.log(currentBanner)
 
  
const handleNextBanner = () => {
  let currentPosition = allCapsules.indexOf(currentBanner)
  currentPosition++;
  if (currentPosition > allCapsules.length-1) {
     currentPosition = 0;
   }
  setCurrentBanner(allCapsules[currentPosition]);
 };

 const handlePrevBanner = () => {
  let currentPosition = allCapsules.indexOf(currentBanner)
  currentPosition--;
  if (currentPosition < 0) {
     currentPosition = allCapsules.length-1;
   }
  setCurrentBanner(allCapsules[currentPosition]);
 };

 let image = "";
 switch (currentBanner?.ThemeId) {
   case 1:
    image = Image1;
    break;

  case 2:
    image = Image2;
    break;

  case 3:
    image = Image3;
    break;

  case 4:
    image= Image4;
    break;

  default:
    image= Image1;
 }
 return (
  <div style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
      <div>
        
          {<BannerCapsule
            banner={currentBanner}
            image={image}
          />}
            {allCapsules.length >1 && (
            <div className={style.botones}>
            <button
              onClick={handlePrevBanner}> {(leng) ? "Anterior" : "Previous"}
            </button>
            <button
              onClick={handleNextBanner}> {(leng) ? "Siguiente": "Next"}
            </button>
            </div> )}
         
      
      </div>

   
  </div>
);

}

export default Capsules;