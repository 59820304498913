import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LabelAndInput from "../../labelAndInput/LabelAndInput";
import axios from "axios";
import validate from './validate';
import s from "./Editen.module.css"
import Swal from 'sweetalert2';
import placeHolderPhoto from "../../../assets/placeholder foto.jpg"
import { userLoggedIn, getAllThemes } from "../../../redux/actions/index";




const EditPerfilen = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useParams();
  const darkMode = useSelector((state) => state.darkMode);
  const themes = useSelector((state) => state.allThemes);

  
  const [photoUser, setPhotoUser] = useState(placeHolderPhoto);
  const [input, setInput] = useState({
    name: "",
    lastname: "",
    email: "",
    profile_picture: "",
    phone: "",
    admin: "",
    themes : [],
  });
  const [errors, setErrors] = useState({
    // name: "",
    // lastname: "",
    // phone: "",
  })

 

useEffect (() => {
  async  function getByID()  {
    const { data } = await axios.get(`/user/${email}`)
    console.log(data)
       let newTheme = [];
       
       if (data.Themes){
          for (let i=0; i<data.Themes?.length; i++) {
             newTheme.push(data.Themes[i].name)
          }
       }
  

    setInput({name: data.name, lastname: data.lastname, email: data.email, 
      phone: data.phone, profile_picture: data.profile_picture, admin: data.admin, themes: newTheme})
      if (data) {
        if(data.admin === true) {
            document.getElementById('admin').checked = true
        }
      }

  }
  
  getByID()
  dispatch(getAllThemes())
  }, [email, dispatch])

  const mostrarAlerta = (iconType, msjText) => {
    Swal.fire({
      confirmButtonColor: '#edb40b',
      icon: iconType,
      title: '',
      text: msjText,
    });
  };


 


  const formHandler = (event) => {
    console.log(event.target.value)
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
    setErrors(
      validate({
        ...input,
        [event.target.name]: event.target.value
      })
    )
       
  };
  console.log(input.admin)

  const selectHandler = (event) => {
    console.log(event.target)
    if (!(input.themes.includes(event.target.value))) {
    setInput({
      ...input,
      themes: [...input.themes, event.target.value]
    })};    
  };

  console.log(input.themes)

  const removeHandler = (event) => {
    event.preventDefault()    
    const filteredTheme = input.themes.filter(theme => theme !== (event.target.value))
    setInput({
      ...input,
      themes: filteredTheme
    })
  }

  const volver = () => {
    navigate('/');
  }

  const handleChangeImage = (event) => {  
    const file = event.target.files[0]
    if(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function charge () {
        console.log(reader.result)
        setInput({
          ...input,
          [event.target.name]:reader.result,
        })
        setPhotoUser(reader.result) 
      }     
           
    } else {
      setInput({...input, [event.target.name]: ""})
      setPhotoUser(placeHolderPhoto)
    } 
    return
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      console.log(input)
      const long = Object.values(errors);
          if (long.length === 0) {

            let selected = [];
            for (let option of input.themes){
              for (let chose of themes) {
                if (option === chose.name ) selected.push(chose.id);
              }
              
            }
              input.idTheme = selected; 
              if(document.getElementById('admin').checked) {input.admin=true}
                else {input.admin= false}      
              await axios.put(`/user/${email}`, input);
              mostrarAlerta('success' , 'User was successfully updated' );
              dispatch(userLoggedIn(email));
              navigate('/');          
          } else mostrarAlerta('error', 'You must complete all fields without errors')

    } catch (error) {
      console.log(error)
      mostrarAlerta('error' ,error.response.data);
      
    }
  };

  return (
    <div className={`${s.container} ${darkMode && s.darkMode}`}>
      <form className={`${s.form} ${s["s-formedit"]}`} onSubmit={submitHandler}>
      <label onClick={volver} className ={s.close}>X</label>
        <fieldset>
        
        
        <div className={s.dataperfil}>
          <div className={s.dataname}>
              <legend>Complete Profile</legend>
              <h3>{input.email}</h3>
              <img src={input.profile_picture? input.profile_picture: photoUser} alt="" />
              <label
                className={s.buttonfile}
                htmlFor = "profile_picture"> Upload Photo
                <input
                className={s.inputfile}
                type="file"
                name="profile_picture"
                id="profile_picture"
                onChange={handleChangeImage}
                />
              </label> 
          
              < LabelAndInput
                label="Name"
                type="text"
                name="name"
                value={input.name}
                handler={formHandler}
              />
              {errors.name && <p>{errors.name}</p>}
              <LabelAndInput
                label="Lastname"
                type="text"
                name="lastname"
                value={input.lastname}
                handler={formHandler}
              />
              {errors.lastname && <p>{errors.lastname}</p>}
          </div>

          <div>
            <LabelAndInput
              label="Phone"
              type="text"
              name="phone"
              value={input.phone}
              handler={formHandler}
            />
            {errors.phone && <p>{errors.phone}</p>}
          
            <div >
              <label>Themes of interest:</label>
              <select name="themes" onChange={selectHandler} className={s.select}>
                <option selected={true} disabled >Select an option</option>
                { themes?.map((them) => <option key={them.id} value={them.name}>{them.name}</option>)}
             </select>
            </div>
            <div className={s.divremove__btn}>
              {input.themes && input.themes?.map((them) => 
                <button value={them} type="remove" onClick={removeHandler} className={s.removebtn}>
              {`X ${them}`}
            </button>
            )}
            </div>
            <div className={s.envio}>
                <input className={s.checkbox} type="checkbox" name="admin" id="admin" value="admin" onChange={formHandler}/>
                <label className={s.mensaje} htmlFor="admin">You accept that periodic information about the chosen topics will be sent to your email. </label>
            </div>
          </div>
        </div>
        <div className={s.final}>
          <button className={s.buttonsub} type="submit">Update</button>
          
          </div>
        </fieldset>
      </form>
      
    </div>
  );
};

export default EditPerfilen;
