const validate = (state) => {
    const errors = {};

    console.log(state)
    
    const patronNumerico = /^[0-9]+$/;
        
   
    if (state.name.length > 50) errors.name = 'Debe ser menos de 50 caracteres';

    if (state.lastname.length > 50) errors.lastname = 'Debe ser menos de 50 caracteres';
      
    if (state.phone && !patronNumerico.test(state.phone)) errors.phone = 'Este campo debe ser numérico'
    
  
    
    return errors;
}

export default validate;