import {useState} from "react";
import style from './Ecoworkingen.module.css';
import nomada from '../../../assets/Ecoworking/Nomada.jpg';
import sala from '../../../assets/Ecoworking/Sala.jpg';
import salon from '../../../assets/Ecoworking/Eco3.jpg';
import {BsWhatsapp} from "react-icons/bs";


const Ecoworkingen = () => {

    const [image, setImage] = useState(salon);


    const handleImage = (event) =>{
         setImage(event.target.name)
    }

    return (
        <div id="eco" className={style.service}>
            <div className={style.imagenes}>
                <img src={image} alt="" className={style.big} />
                <div className={style.littles}>
                    <img src={nomada} alt="" name={nomada} onClick={handleImage}/>
                    <img src={sala} alt="" name={sala} onClick={handleImage}/>
                    <img src={salon} alt="" name={salon} onClick={handleImage}/>
                </div>
            </div>    
            <div className={style.text}>
                    <h1>ECOWORKING</h1>
                    <p>Just 30 minutes from Cali, we offer you an ideal space in the middle of nature, with a spectacular landscape so you can plan as a team.</p>
                    <p>Internet connection</p>
                    <p>Coffee Station</p>
                    <p>Presentation screen</p>
                    <p>Work table for 6 people</p>
                    <p>The Nomad chair</p>
                    <p>The hammock of ideas</p>
                    <p>The Tertulia room</p>
                    <p>You can hire it with Breakfast and/or Lunch</p>
                    <p>Hours: 8:00 a.m. to 5:00 p.m. Monday to Friday</p>
                    <p>Bookings:< BsWhatsapp  size={25}/> 3116233078</p>
            </div>
         
        </div>
    )




}

export default Ecoworkingen;
