import  { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import axios from "axios";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBan } from "@fortawesome/free-solid-svg-icons";
import { getAllUsersAction } from "../../redux/actions/index";
import s from "./usersTable.module.css"
// import { blockUserAction } from "../../redux/actions/actions"; // Importa la nueva acción



const UsersTable = () => {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.allUsers);
  console.log(usersData)

  useEffect(() => {
    dispatch(getAllUsersAction());
  }, []);

  // const handleBlockUser = async (email) => {
  //   await axios.put(`/user/restore/${email}`);
  //   dispatch(getAllUsersAction());
  // };

  return (
    <div className={s.tablecontainer}>
      <h2>Usuarios</h2>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Telefono</th>
            <th>Español</th>
            <th>Enviar a Correo</th>
            <th>Admin</th>
          </tr>
        </thead>
        <tbody>
          {usersData.map((user) => (
            <tr key={user.email}>
              <td>{user.email}</td>
              <td>{user.name}</td>
              <td>{user.lastname}</td>
              <td>{user.phone}</td>
              <td>{user.spanish.toString()}</td>
              <td>{user.sendmail.toString()}</td>
              <td>{user.admin.toString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
