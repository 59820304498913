import {useState} from "react";
import style from './Farmersen.module.css';
import nomada from '../../../assets/Farmers/Explicacion.jpg';
import sala from '../../../assets/Farmers/En la Paca.jpg';
import salon from '../../../assets/Farmers/Subiendo.jpg';
import {BsWhatsapp} from "react-icons/bs";


const Farmersen = () => {

    const [image, setImage] = useState(salon);


    const handleImage = (event) =>{
         setImage(event.target.name)
    }

    return (
        <div id="farm" className={style.service}>
            <div className={style.imagenes}>
                <img src={image} alt="" className={style.big} />
                <div className={style.littles}>
                    <img src={nomada} alt="" name={nomada} onClick={handleImage}/>
                    <img src={sala} alt="" name={sala} onClick={handleImage}/>
                    <img src={salon} alt="" name={salon} onClick={handleImage}/>
                </div>
            </div>    
            <div className={style.containt}>
                    <h1 >LITTLE FARMERS</h1>
                    
                        <p>The farm as an experimental center for environmental conservation offers schools the opportunity for their students to learn in a fun way:</p>
                        <p>Sow in the orchard</p>
                        <p>Collect eggs from happy chickens</p>
                        <p>Interact with the ducks</p>
                        <p>Feed the sheep</p>
                        <p>Feed the fish</p>
                        <p>Prepare compost</p>
                        <p>Interact with rabbits</p>
                        <p>Hours: 8:00 a.m. to 1:00 p.m. Monday to Friday</p>
                        <p>Bookings:  < BsWhatsapp  size={25}/>3116233078</p>
                    
            </div>
         
        </div>
    )




}

export default Farmersen;
