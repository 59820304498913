import React from "react";
import { AiFillFacebook, AiFillInstagram, AiFillMail } from "react-icons/ai";
import {BsWhatsapp} from "react-icons/bs";
import style from "./SlideBar.module.css";



const SlideBar = () => {
  const links = [
    {
      id: 1,
      child: (
        <>
          <AiFillFacebook size={30}  />
        </>
      ),
      href: "https://www.facebook.com/search/top?q=la%20granja%20de%20pili",
    },
    {
      id: 2,
      child: (
        <>
          <AiFillInstagram size={30} />
        </>
      ),
      href: "https://www.instagram.com/lagranjadepili?igsh=MTQ4ZHVxeG15ZnFsaA==",
    },
    
    {
      id: 3,
      child: (
        <>
          <AiFillMail size={30} />
        </>
      ),
      href: "mailto:lagranjadepili@gmail.com",
    },
    {
      id: 4,
      child: (
        <>
           < BsWhatsapp  size={25}/>
        </>
      ),
      href: "https://wa.me/" + 573116233078,
    },
    
  ];

  return (
    <div className={style.content}>
      {links.map(({ id, child, href, download }) => (
        <div key={id}>
          <a href={href}
          
            download={download}
            target="_blank"
            rel="noreferrer"
          >
            {child}
          </a>
        </div>
      ))}
      
    </div>
    
  );
};

export default SlideBar;

