import Image1 from '../../assets/pollo foto.jpg';
import Image2 from '../../assets/capsula.jpg';
import Image3 from '../../assets/cursos.jpg';
import style from './Dashboard.module.css';

const Dashboard = () => {
  let currentCards = [];
  

  const images = [Image1, Image2, Image3];
  const title = ["Usuarios",
                  "Capsulas", 
                  "Cursos"];
  const href = ["/usersadmin", "/capsulesadmin", "/courseadmin"]
  
  for (let i=0; i< 3; i++) {
      currentCards.push({id:i, image:images[i], title:title[i], href:href[i]})
  }
   
return (
    <div className={style.actividad}>
      <p className={style.title}>Administración de la Página</p>
      <div className={style.tarjetas}>
        {currentCards.map((p) => (
          <div key={p.id}>
            <a href={p.href}>
            
              <div className={style.card}>
                <img className={style.imagen}src={p.image} alt="" />
                <div className={style.descripcion}>
                    <h2 className={style.name}>{p.title}</h2>
                    
                </div>
              </div>
            
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;