import {useState} from "react";
import style from './Ecoworking.module.css';
import nomada from '../../assets/Ecoworking/Nomada.jpg';
import sala from '../../assets/Ecoworking/Sala.jpg';
import salon from '../../assets/Ecoworking/Eco3.jpg';
import {BsWhatsapp} from "react-icons/bs";


const Ecoworking = () => {

    const [image, setImage] = useState(salon);


    const handleImage = (event) =>{
         setImage(event.target.name)
    }

    return (
        <div id="eco" className={style.service}>
            <div className={style.imagenes}>
                <img src={image} alt="" className={style.big} />
                <div className={style.littles}>
                    <img src={nomada} alt="" name={nomada} onClick={handleImage}/>
                    <img src={sala} alt="" name={sala} onClick={handleImage}/>
                    <img src={salon} alt="" name={salon} onClick={handleImage}/>
                </div>
            </div>    
            <div className={style.text}>
                    <h1>ECOWORKING</h1>
                    <p>A sólo 30 minutos de Cali te brindamos un espacio ideal en medio de la naturaleza, con un paisaje espectacular para que planees en equipo.</p>
                    <p>Conexión a Internet</p>
                    <p>Estación de Café</p>
                    <p>Pantalla para presentaciones</p>
                    <p>Mesa de trabajo para 6 personas</p>
                    <p>La silla Nómada</p>
                    <p>La hamaca de las Ideas</p>
                    <p>La sala de la Tertulia</p>
                    <p>Puedes contratarlo con Desayuno y/o Almuerzo</p>
                    <p>Horario: 8:00 a.m a 5:00 p.m. de Lunes a Viernes </p>
                    <p>Reservas:  < BsWhatsapp  size={25}/> 3116233078</p>
            </div>
         
        </div>
    )




}

export default Ecoworking;
