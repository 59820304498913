// Hero.js
import React, { useState} from 'react';
//import s from './hero.module.css';
import Banner from '../Banner/Banner';
import colection1Image from '../../assets/Cabaña3.jpg';
import colection2Image from '../../assets/Gallinas1.jpg';
import colection3Image from '../../assets/Energias.png';

const Hero = () => {
  let currentCards = [];
  

  const images = [colection1Image, colection2Image, colection3Image];
  const title = ["Qué está a nuestro alcance para mitigar el calentamiento global?",
                  "Quieres conocer más sobre soberanía alimentaria?", 
                  "Cómo funcionan las energías renovables?"];
  const text = ["Desde casa podemos implementar acciones para aprovechar los residuos sólidos orgánicos y aplicar la economía circular",
                "Te enseñamos a producir alimentos libre de químicos en pequeños espacios, a construir huertas orgánicas y a producir compostaje",
              "Con nosotros podrás conocer como funciona un sistema fotovoltaico, un calentador de agua solar o un biodigestor"]

  
  for (let i=0; i<= 3; i++) {
      currentCards.push({id:i, image: images[i], title:title[i] , text:text[i]})
  }
  const [currentBanner, setCurrentBanner] = useState(currentCards[0]);
 
  
  const handleNextBanner = () => {
    let currentPosition = currentBanner.id
    currentPosition++;
    if (currentPosition > currentCards.length-2) {
       currentPosition = 0;
     }
    setCurrentBanner(currentCards[currentPosition]);
   };

   const handlePrevBanner = () => {
    let currentPosition = currentBanner.id
    currentPosition--;
    if (currentPosition < 0) {
       currentPosition = 2;
     }
    setCurrentBanner(currentCards[currentPosition]);
   };


  return (
    <div >
        <div>
          
            {<Banner
              banner={currentBanner}
              handleNextBanner={handleNextBanner}
              handlePrevBanner={handlePrevBanner}

            />}
        
        </div>

     
    </div>
  );
};

export default Hero;