import style from "./Presentationen.module.css"
import water from "../../../assets/Presentation/agua.jpg"
import sun from "../../../assets/Presentation/sol.png"
import nature from "../../../assets/Presentation/Naturaleza.png"
import logo from "../../../assets/logo-pili.jpg"

const Presentationen = () => {

    
return (
    <div className={style.present}>
        <div className={style.title}>
            <p>We are a family business for the care of the environment</p>
            <img src={logo} alt="logo"/>
        </div>
        <div className={style.paragrah}>
            <div className={style.component}>
            <img src={water} alt=""/>
            <div className={style.group}>
                <h2>Water</h2>
                <p>Acuaponics, use of rainwater and controlled irrigation</p>
            </div>
            </div>

            <div className={style.component}>
                <img src={sun} alt=""/>
            <div className={style.group}>
                <h2>Energy</h2>
                <p>Solar water heater, photovoltaic system and energy efficiency</p>
            </div>
            </div>

            <div className={style.component}>
                <img src={nature} alt=""/>
            <div className={style.group}>
                <h2>Nature</h2>
                <p>Bird diversity, native species and sustainable production</p>
            </div>
            </div>
        </div>

    </div>

)

}

export default Presentationen