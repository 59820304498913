import {useState} from "react";
import style from './Agro.module.css';
import entrada from '../../assets/Agrotourism/Entrada.jpg';
import conejo from '../../assets/Agrotourism/Pilar_conejo.jpg';
import gallinas from '../../assets/Agrotourism/Gallinas.jpg';
import {BsWhatsapp} from "react-icons/bs";


const Agrotourism = () => {

    const [image, setImage] = useState(entrada);


    const handleImage = (event) =>{
         setImage(event.target.name)
    }

    return (
        <div id="agro" className={style.aservice}>
            <div className={style.aimagenes}>
                <img src={image} alt="" className={style.abig} />
                <div className={style.alittles}>
                    <img src={entrada} alt="" name={entrada} onClick={handleImage}/>
                    <img src={conejo} alt="" name={conejo} onClick={handleImage}/>
                    <img src={gallinas} alt="" name={gallinas} onClick={handleImage}/>
                </div>
            </div>    
            <div className={style.atext}>
                    <h1>AGROTURISMO</h1>
                    <p>Te invitamos a que realices un recorrido donde puedes aprender de:</p>
                    <p>Acuaponia</p>
                    <p>Huertas Orgánicas</p>
                    <p>Sistema de Recolección de Aguas Lluvias</p>
                    <p>Gallinas Ponedoras en Pastoreo</p>
                    <p>Cultivo de Mosca Soldado</p>
                    <p>Apicultura (opcional)</p>
                    <p>Observación de Aves</p>
                    <p>Puedes contratarlo con Desayuno y/o Almuerzo</p>
                    <p>Horario: 8:00 a.m a 5:00 p.m. de Lunes a Viernes </p>
                    <p>Reservas:  < BsWhatsapp  size={25}/> 3116233078</p>
            </div>
         
        </div>
    )




}

export default Agrotourism;