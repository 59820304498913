const validate = (state) => {
    const errors = {};
    
       
    if(!state.spanish) errors.spanish = 'El campo es requerido'
    if (state.spanish.length > 500){
      errors.spanish = 'Debe ser máximo de 500 caracteres';
    }
   
    if(!state.english) errors.english = 'El campo es requerido'
    if (state.english.length > 500){
      errors.english = 'Debe ser máximo de 500 caracteres';
    }

    if(!state.theme) errors.theme = 'Debe seleccionar una opción'
    
    return errors;
}

export default validate;