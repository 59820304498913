import style from "./Presentation.module.css"
import water from "../../assets/Presentation/agua.jpg"
import sun from "../../assets/Presentation/sol.png"
import nature from "../../assets/Presentation/Naturaleza.png"
import logo from "../../assets/logo-pili.jpg"

const Presentation = () => {

    
return (
    <div className={style.present}>
        <div className={style.title}>
            <p>Somos un emprendimiento familiar por el cuidado del medio ambiente</p>
            <img src={logo} alt="logo"/>
        </div>
        <div className={style.paragrah}>
            <div className={style.component}>
            <img src={water} alt=""/>
            <div className={style.group}>
                <h2>Agua</h2>
                <p>Acuaponía, utilización de aguas lluvias y riego controlado</p>
            </div>
            </div>

            <div className={style.component}>
                <img src={sun} alt=""/>
            <div className={style.group}>
                <h2>Energía</h2>
                <p>Calentador de agua solar, sistema fotovoltaíco y eficiencia enegética</p>
            </div>
            </div>

            <div className={style.component}>
                <img src={nature} alt=""/>
            <div className={style.group}>
                <h2>Naturaleza</h2>
                <p>Diversidad de aves, especies nativas y producción sostenible </p>
            </div>
            </div>
        </div>

    </div>

)

}

export default Presentation