import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import validate from "./validate";
import { useNavigate } from "react-router-dom";
import LabelAndInput from "../../labelAndInput/LabelAndInput";
import s from "./Createn.module.css";

//const URL = import.meta.env.VITE_URL


const CreateUseren = () => {

  const prod = useSelector((state)=>state.product);
  const navigate = useNavigate()
  const [input, setInput] = useState({
    name: "",
    lastname: "",
    password: "",
    passwordRep: "",
    email: "",
    profile_picture:
      "https://t3.ftcdn.net/jpg/01/09/00/64/360_F_109006426_388PagqielgjFTAMgW59jRaDmPJvSBUL.jpg",
    phone: "",
    provider: "",
    spanish: false,
    admin: false
  });

  const [errors, setErrors] = useState({
    password: "",
    passwordRep: "",
    email: "",
  });

  const mostrarAlerta = (iconType, msjText) => {
    Swal.fire({
      confirmButtonColor: '#edb40b',
      icon: iconType,
      title: "",
      text: msjText,
    });
  };

  const formHandler = (event) => {
    setInput({
      ...input,
      [event.target.name]: event.target.value,
    });
    setErrors(
      validate({
        ...input,
        [event.target.name]: event.target.value,
      })
    );
  };

  const comeback = () => {
    navigate('/login');
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const long = Object.values(errors);
      if (long.length === 0 && input.passwordRep) {
        await axios.post(`/user`, input);
        mostrarAlerta("success", "User was created successfully");
        setInput({
          password: "",
          passwordRep: "",
          email: "",
        });
      } else if (long.length !==0 || !input.passwordRep) mostrarAlerta("error", "You must complete all fields without errors");
    } catch (error) {
      console.log(error);
      mostrarAlerta("error", error.response.data);
    }
  };

  return (
    <div className={s.container} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
    <div className={s.form__container}>
      <form className={`${s.form} ${s["s-form"]}`} onSubmit={submitHandler}>
      <button onClick={comeback} className ={s.close}>x</button>
        <fieldset>
          <legend>Create User</legend>

          <LabelAndInput
            label="Email*"
            type="text"
            name="email"
            value={input.email}
            handler={formHandler}
          />
          {errors.email && <p>{errors.email}</p>}

          <LabelAndInput
            label="Password*"
            type="password"
            name="password"
            value={input.password}
            handler={formHandler}
          />
          {errors.password && <p>{errors.password}</p>}
          <LabelAndInput
            label="Confirmation*"
            type="password"
            name="passwordRep"
            value={input.passwordRep}
            handler={formHandler}
          />
          {errors.passwordRep && <p>{errors.passwordRep}</p>}
          <span>*Mandatory</span>
          <br></br>
          <button className={s.btnC} type="submit">Create</button>
        </fieldset>
      </form>
    </div>
    </div>
  );
};

export default CreateUseren;
