
import style from './Abouten.module.css'
import fotopili from '../../../assets/Pilar_conejo2.png'
import fotoluisma from '../../../assets/Luismita.jpg'
import libro from '../../../assets/Portada Pili.jpg'
import { Link } from  "react-router-dom"
import { useSelector } from 'react-redux'

const Abouten = () =>{
 
    
    const prod = useSelector((state)=>state.product);
     return(
        <div className={style.about} style = {{ opacity:prod ? '0.2':'1', pointerEvents: prod ? 'none':'auto'}}>
            <div className={style.container}>
                <h1>¿About us?</h1>
                <p>La Granja de Pili is an experimental center for environmental conservation
                whose mission is to transmit knowledge about sustainable production processes,
                use of renewable energies, conservation of native species and food sovereignty.
                </p>
                <p>
                We seek to awaken in people the care for natural resources and show them how
                adopt good environmental practices. We provide
                advice on formulation and execution of environment conservation projects,
                sustainable agricultural production and energy efficiency.
                </p>
            </div>

            <div className={style.presentation}>
                <div className={style.pili}>
                    <img src={fotopili} alt="foto Pilar"/>
                    <Link to ='/acknowledge'><img src={libro} alt="libro Pilar" className={style.libro}/></Link>
                    <h3>Maria del Pilar Dacosta Chavez</h3>
                    <p>Zootechnician from the National University with specialization in Sustainable Animal Nutrition. Technician
                    in sustainable use of plant biodiversity. Studies in circular economy and profuction ecological animal.
                    In 2023, Maria del Pilar was included in the <Link to='/acknowledge'>Book Ellas Emprenden</Link> of Promujer.
                    </p>
                </div>
                <div className={style.luisma}>
                    <img src={fotoluisma} alt="foto Luisma"/>
                    <h3>Luis Mario Lenis Gil</h3>
                    <p>Electronics Engineer from the University of Cauca, specialist in Engineering Management Systems. Master in environment 
                        and Renewable Energies. Certificate for PMI in Project Management. Software developer.
                    </p>
                </div>
            </div>


       </div>
      
        
     )
     }
  export default Abouten