import React from "react";
import { useSelector } from 'react-redux';
import style from "./BannerCapsule.module.css";
import logo from '../../assets/logo-pili.jpg';
//import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

//import { useSelector } from "react-redux";


const BannerCapsule = ( {banner, image}) => {
  const { spanish, english } = banner;
  console.log(banner);
  const leng = useSelector((state)=>state.lenguage);
   
  // const flechaIzquierda = <FontAwesomeIcon icon={faArrowLeft} />;
  // const flechaDerecha = <FontAwesomeIcon icon={faArrowRight} />;

  if(leng) { 
    return (
        <div className={style.actividad}>
          <h1>Sabías qué?</h1>
                <div className={style.card}>
                    <img className={style.imagen}src={image} alt="" />
                    <div className={style.descripcion}>
                        <p>{spanish}</p>
                        <img src={logo} alt=""/>
                        
                        
                    </div>
                </div>
                
          </div>
      );
    
    } else {
    
      return (
        <div className={style.actividad}>
          <h1>Did you know?</h1>
                <div className={style.card}>
                    <img className={style.imagen}src={image} alt="" />
                    <div className={style.descripcion}>
                        <p>{english}</p>
                        <img src={logo} alt=""/>
                        
                        
                    </div>
                </div>
            
          </div>
    
      );
    } 
     };

 export default BannerCapsule;
