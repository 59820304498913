import {useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-pili.jpg'
import English from '../../assets/bandera EEUU.png';
import Colombian from '../../assets/bandera Colombia.png';
import style from './Navbar.module.css'
import { useDispatch, useSelector } from "react-redux"
import { getLenguage, getProduct, setAccess, userLogOut  } from '../../redux/actions/index';
import { signOutFunction } from "../../firebase/firebase.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faGear, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";


const Navbar = () =>{

  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [showMenu,setShowMenu] = useState(true);
  const [miEstilo,setMiEstilo] = useState({left:-250});
  
  const dispatch = useDispatch();
  const handleChange=() => {
    dispatch (getLenguage(false));
  }

  const handleMenu =() => {
    setShowMenu(!showMenu)
    if(window.innerWidth <= 858) {
      if(showMenu)  setMiEstilo({left:0})
      else setMiEstilo({left: -250})
    }
  }

  const handleOption = () => {
    setShowOptions(!showOptions)
  }


  const handleLogout = () => {
    dispatch(setAccess(false));
    dispatch(userLogOut());
    signOutFunction();
    localStorage.clear();
    setShowOptions(false);
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleProduct = () => {
    dispatch (getProduct(true))
  }

  const { access, activeUser, product } = useSelector((state) => state);
  

   return(
      <nav className={style.navbar} style = {{ opacity:product ? '0.2':'1', pointerEvents: product ? 'none':'auto'}}>
        <div className={style.contentimage}>
  
          <img src={logo} alt='' onClick={handleProduct}/>
          
        </div>
        <div className={style.section} style={miEstilo}>
        {activeUser?.admin && (
              <Link to="/dashboard">
                <FontAwesomeIcon icon={faGear} onClick={handleMenu} />
              </Link>
            )}
            <Link className={style.link} onClick={handleMenu} to='/' >Inicio</Link>
            <Link className={style.link} onClick={handleMenu} to='/about' >Quienes somos</Link>
            <Link className={style.link} onClick={handleMenu} to='/activities' >Actividades</Link>
            <Link className={style.link} onClick={handleMenu} to='/capsules' >Pilosito</Link>
            <Link className={style.link} onClick={handleMenu} to='/contact' >Contacto</Link>
            {!access && (
            <Link className={style.link} onClick={handleMenu} to="/login">Iniciar Sesión</Link>
            )}
        </div>
        <div className={style.sesion}>    
          {access && (
          <div
            className={style.photo__container}
            onClick={handleOption}
          >
            <img
              src={activeUser?.profile_picture}
              alt=""
              className={style.user__photo}
            />
          </div>
        )}

        {showOptions && (
          <div className={style.user__options}>
            <p>{activeUser?.email}</p>
            <Link to={`/editperfil/${activeUser?.email}`} onClick={handleOption}>
              <button>Completar perfil</button>
            </Link>
            <button onClick={handleLogout}>Terminar sesión</button>
          </div>
        )}
         
        </div>

          <div className={style.idioms}>
            <img src={English} alt=''/>
            <input id="lenguage" type="checkbox" checked value="false" onChange={handleChange} className={style.check}/>
            <img src={Colombian} alt=''/>
          </div>
          <div className={style.hamburguer}>
            {
              miEstilo.left===-250 && <FontAwesomeIcon icon= {faBars} className = {style.checkbtn} onClick={handleMenu}/>
            }
            {
              miEstilo.left===0 && <FontAwesomeIcon icon= {faTimes} className = {style.checkbtn} onClick={handleMenu}/>
            }
          </div>
            
        </nav>
  )
}
    

export default Navbar